html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  background-color: #f7f8fa;
  font-family: "Aktiv Grotesk", aktiv-grotesk, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

section {
  margin: 40px 0;
}
.specs {
  color: #28bf92;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
}
article {
  margin: 4px;
}

.main-content {
  margin: 2.5rem auto 5rem;
  width: 100%;
}

#active-applications-grid {
  display: flex;
  flex-wrap: wrap;
}

#inactive-applications-grid {
  display: flex;
  flex-wrap: wrap;
}

body {
  background-color: #f7f8fa;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.inavtive-application {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
